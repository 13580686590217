import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Loading from "./LoadingAnimation";

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart = ({
  data,
  isLoaded = true,
  error = null,
  title,
  labelKey = "key",
  valueKey = "value",
}) => {
  const sortedData = data.sort((a, b) => b[valueKey] - a[valueKey]);
  const topEntries = sortedData.slice(0, 5);
  const othersValue = sortedData
    .slice(5)
    .reduce((sum, item) => sum + parseInt(item[valueKey]), 0);
  const otherEntry =
    othersValue > 0 ? [{ [labelKey]: "Others", [valueKey]: othersValue }] : [];

  const finalData = [...topEntries, ...otherEntry];

  const chartData = {
    labels: finalData.map((item) => item[labelKey]),
    datasets: [
      {
        label: "Applications Usage",
        data: finalData.map((item) => parseInt(item[valueKey])),
        backgroundColor: [
          "#2b86b3",
          "#3db0cb",
          "#64d9dd",
          "#2e366c",
          "#2c5d93",
          "#FFCE56",
          "#4BC0C0",
          "#FF9F40",
          "#FF6384",
          "#36A2EB",
        ],
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "50%",
    plugins: {
      legend: {
        position: "right",
        labels: {
          boxWidth: 10,
          font: {
            size: 10,
          },
        },
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  return (
    <div
      style={{
        backgroundColor: "#F5F5F5",
        padding: "10px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        minHeight: "300px",
      }}
    >
      <p
        style={{
          margin: "0 0 10px 0",
          fontWeight: "bold",
          fontSize: "16px",
          textAlign: "left",
        }}
      >
        {title}
      </p>
      {error ? (
        <p> Sorry! An error occurred while fetching the data. </p>
      ) : isLoaded && data.length === 0 ? (
        <p>No data available</p>
      ) : isLoaded ? (
        <div style={{ flex: 1, minHeight: "300px" }}>
          <Doughnut data={chartData} options={options} />
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default DonutChart;
