// src/TopNavBar.js
import "./TopNavBar.css";
import "bootstrap/dist/css/bootstrap.min.css";

const TopNavBar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img
            src="https://mahdee-kamal.sirv.com/logo.png"
            alt="Blog Logo"
            style={{ maxHeight: "60px" }}
          />
        </a>
        <div className="d-flex align-items-center justify-content-end">
          <div className="navbar-user">
            <h6>
              Hello Tom <b>Salvat</b>
            </h6>
            <span className="down-arrow">&#9662;</span>
          </div>

          <span>
            <img
              src="https://mahdee-kamal.sirv.com/Tom%20Salvat%20LinkedIn_edited.webp"
              alt="User Avatar"
              className="navbar-avatar"
            />
          </span>
        </div>
      </div>
    </nav>
  );
};

export default TopNavBar;
