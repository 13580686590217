import React, { useState, useMemo } from "react";
import SingleColumnTable from "./SingleColumnTable";
import InfoTooltip from "./InfoTooltip";

const QueryFilterDropdown = ({
  data = [],
  isLoaded = true,
  error = null,
  columnKey = "value",
  columnTitle = "Column",
  tooltipText = null,
  defaultNumberOfRows = 10,
}) => {
  // Get unique cluster names for dropdown
  const uniqueClusterNames = useMemo(() => {
    const names = [...new Set(data.map((q) => q.cluster_name))];
    return ["all", ...names];
  }, [data]);

  // State for selected cluster
  const [selectedCluster, setSelectedCluster] = useState("all");

  // Filter queries based on selected cluster
  const filteredQueries = useMemo(() => {
    if (!isLoaded || error || !data) {
      return [];
    }

    if (selectedCluster === "all") {
      return data;
    }
    return data.filter((q) => q.cluster_name === selectedCluster);
  }, [data, selectedCluster, isLoaded, error]);

  return (
    <div
      className="query-filter"
      style={{ backgroundColor: "#F5F5F5", paddingTop: "10px", height: "100%" }}
    >
      <div className="QueryFilter-Filter" style={{ paddingLeft: "10px" }}>
        <b> {columnTitle}</b>{" "}
        {tooltipText && <InfoTooltip tooltipText={tooltipText} />}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <label className="mr-2">Filter by Cluster: </label>
        &nbsp;&nbsp;
        <select
          className="p-2 border rounded"
          value={selectedCluster}
          onChange={(e) => setSelectedCluster(e.target.value)}
          disabled={!isLoaded || error}
        >
          {uniqueClusterNames.map((clusterName) => (
            <option key={clusterName} value={clusterName}>
              {clusterName}
            </option>
          ))}
        </select>
      </div>

      <SingleColumnTable
        key={selectedCluster}
        data={filteredQueries}
        isLoaded={isLoaded}
        error={error}
        columnKey={columnKey}
        tooltipText={tooltipText}
        defaultNumberOfRows={defaultNumberOfRows}
      />
    </div>
  );
};

export default QueryFilterDropdown;
