import React, { useState } from "react";
import SideBar from "./SideBar";
import TopNavBar from "./TopNavBar";
import SearchBar from "./SearchBar";

const EngineRoom = () => {
  const [startDate, setStartDate] = useState(new Date("2024-01-01"));
  const [endDate, setEndDate] = useState(new Date("2024-12-31"));

  return (
    <div>
      <SideBar current_page={"engine-room"} />
      <div className="main-page">
        <TopNavBar />
        <SearchBar
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <div className="dashboard">
          <h2> Elelem Engine Room </h2>
          This page is under construction. Please check back later.
        </div>
        <br />
      </div>
    </div>
  );
};

export default EngineRoom;
