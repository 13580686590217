import "./SideBar.css";

const SideBar = ({ current_page }) => {
  return (
    <div className="sidebar">
      <a
        href="/control-room"
        title="Control Room"
        aria-label="Settings"
        style={{ color: current_page === "control-room" ? "white" : "#BBEB02" }}
      >
        <i className="bi bi-gear"></i>
        <div className="sidebar-text">Control Room</div>
      </a>

      <a href="/analytics" title="Analytics" aria-label="Analytics">
        <img
          src={`/analytics_logo_${
            current_page === "analytics" ? "selected" : "primary"
          }.png`}
          alt="Analytics Icon"
          className="custom-icon"
        />
        <div
          className="sidebar-text"
          style={{ color: current_page === "analytics" ? "white" : "#BBEB02" }}
        >
          Analytics
        </div>
      </a>

      <a href="/engine-room" title="Engine Room" aria-label="Engine Room">
        <img
          src={`/analytics_logo_${
            current_page === "engine-room" ? "selected" : "primary"
          }.png`}
          alt="Analytics Icon"
          className="custom-icon"
        />
        <div
          className="sidebar-text"
          style={{
            color: current_page === "engine-room" ? "white" : "#BBEB02",
          }}
        >
          Engine Room
        </div>
      </a>
    </div>
  );
};

export default SideBar;
