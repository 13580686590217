import React from "react";

const InfoTooltip = ({ tooltipText, placement = "right" }) => {
  return (
    <i
      className="bi bi-info-circle text-secondary ms-2"
      data-bs-toggle="tooltip"
      data-bs-placement={placement}
      data-bs-html="true"
      title={tooltipText}
    />
  );
};

export default InfoTooltip;
