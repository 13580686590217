import React, { useState, useMemo } from "react";
import SingleColumnTable from "./SingleColumnTable";

const QueryFilterTabs = ({
  data = [],
  isLoaded = true,
  error = null,
  columnKey = "value",
  columnTitle = "Column",
}) => {
  // Get unique cluster names for tabs
  const uniqueClusterNames = useMemo(() => {
    const names = [...new Set(data.map((q) => q.cluster_name))];
    return ["all", ...names];
  }, [data]);

  // State for selected cluster
  const [selectedCluster, setSelectedCluster] = useState("all");

  // Filter queries based on selected cluster
  const filteredQueries = useMemo(() => {
    if (!isLoaded || error || !data) {
      return [];
    }

    if (selectedCluster === "all") {
      return data;
    }
    return data.filter((q) => q.cluster_name === selectedCluster);
  }, [data, selectedCluster, isLoaded, error]);

  const handleTabChange = (clusterName) => {
    setSelectedCluster(clusterName);
  };

  return (
    <div className="container-fluid p-0" style={{ backgroundColor: "#F5F5F5" }}>
      <div className="row">
        {/* Left side vertical tabs */}

        <div className="col-4 pe-0" style={{ border: "5px" }}>
          <h6 className="fw-bold ps-3" style={{ paddingTop: "15px" }}>
            Query Groups (Clusters)
          </h6>
          <div className="nav flex-column nav-pills" role="tablist">
            {uniqueClusterNames.map((clusterName) => (
              <button
                key={clusterName}
                className={`nav-link text-start border-0 rounded-0`}
                onClick={() => handleTabChange(clusterName)}
                disabled={!isLoaded || error}
                style={{
                  backgroundColor:
                    selectedCluster === clusterName ? "#fff" : "transparent",
                  color: "#666",
                  opacity: !isLoaded || error ? 0.5 : 1,
                  padding: "10px 15px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
              >
                {clusterName}
              </button>
            ))}
          </div>
        </div>

        {/* Right side content */}
        <div className="col-8 ps-0">
          {/* <div className="tab-content p-3"> */}
          <SingleColumnTable
            key={selectedCluster}
            data={filteredQueries}
            isLoaded={isLoaded}
            error={error}
            columnKey={columnKey}
            columnTitle={"Tracked Queries"}
          />
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default QueryFilterTabs;
