import { useState, useEffect } from "react";

const useLoadData = (url) => {
  const [requestedData, setRequestedData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoaded(false);
    setError(null);
    setRequestedData([]);

    const fetchData = async () => {
      console.log("Fetching data from:", url);
      try {
        const response = await fetch(url);
        const response_json = await response.json();
        const data = response_json["data"];
        const response_time_ms = response_json["response_time_ms"];
        const bigquery_bytes_billed = response_json["bigquery_cost_mb"];
        console.log(
          "Successfully fetched response from: ",
          url,
          "\nResponse Time (ms): ",
          response_time_ms,
          "\nBigQuery bytes billed:: ",
          bigquery_bytes_billed
        );
        setRequestedData(data);
        setIsLoaded(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
      }
    };

    fetchData();
  }, [url]);

  return [requestedData, isLoaded, error];
};

export default useLoadData;
