import React, { useState, useEffect } from "react";
import "./LoadingAnimation.css"; // Import the CSS file if needed

const Loading = () => {
  const [loadingText, setLoadingText] = useState("Loading");

  useEffect(() => {
    const loadingStates = [
      "Loading",
      "Loading .",
      "Loading . .",
      "Loading . . .",
      "Loading . . . .",
      "Loading . . . . .",
      "Loading . . . . . .",
    ];
    let index = 0;

    const interval = setInterval(() => {
      setLoadingText(loadingStates[index]);
      index = (index + 1) % loadingStates.length;
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return <p className="loading-animation">{loadingText}</p>;
};

export default Loading;
