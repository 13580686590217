import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Analytics from "./Analytics";
import EngineRoom from "./EngineRoom";
import ControlRoom from "./ControlRoom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/analytics" />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/engine-room" element={<EngineRoom />} />
        <Route path="/control-room" element={<ControlRoom />} />
      </Routes>
    </Router>
  );
}

export default App;
