import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import ForceGraph3D from "3d-force-graph";
import neo4j from "neo4j-driver";

function Select({ val, setVal, range, increment, ascending = true }) {
  return (
    <select
      value={val}
      onChange={(e) => {
        setVal(Number(e.target.value));
      }}
    >
      {[...Array(range - 1)].map((_, i) => {
        const v = ascending ? (i + 1) * increment : (range - i - 1) * increment;
        return (
          <option key={i} value={v}>
            {v}%
          </option>
        );
      })}
    </select>
  );
}

function ThreeDGraph() {
  const [topEntitiesPercentage, setTopEntitiesPercentage] = useState(10);
  const [topRelationsPercentage, setTopRelationsPercentage] = useState(90);
  const divRef = useRef(null);

  useEffect(() => {
    const elem = document.getElementById("3d-graph");
    const driver = neo4j.driver(
      "neo4j://2419fd5d.databases.neo4j.io",
      neo4j.auth.basic("neo4j", "bk9ThmIQvdvkCeD9U5DfE55l_mS0zpZFIqPreAYhKe8"),
      { encrypted: true }
    );
    const session = driver.session({ database: "neo4j" });
    const start = new Date();
    const topEntitiesThreshold = 1 - topEntitiesPercentage / 100;
    const topRelationsThreshold = topRelationsPercentage / 100;
    const neo4jQuery = `
      MATCH (n:Entity)
        WITH
          toFloat(max(n.count)) AS maxNodeCount
      MATCH (n:Entity)-[r:RELATED]-(:Entity)
        WHERE n.count = maxNodeCount
        WITH
          maxNodeCount AS maxNodeCount,
          toFloat(max(r.count)) AS maxRelCount
      MATCH (n:Entity)-[r:RELATED]-(m:Entity)
        WHERE n.count > maxNodeCount * ${topEntitiesThreshold} AND r.count > maxRelCount * ${topRelationsThreshold}
      RETURN 
        { id: id(n), caption:n.label, size:toString(n.count), weight:toString(n.count/maxNodeCount) } as source,
        { id: id(m), caption:m.label, size:toString(m.count), weight:toString(m.count/maxNodeCount) } as target,
        {weight:toString(r.count/maxRelCount), size:toString(r.count/5.0), type:type(r)} as rel
      LIMIT $limit;
    `;
    session
      .run(neo4jQuery, { limit: neo4j.int(5000) })
      .then((result) => {
        const nodes = {};
        const links = result.records.map((r) => {
          const source = r.get("source");
          source.id = source.id.toNumber();
          nodes[source.id] = source;

          const target = r.get("target");
          target.id = target.id.toNumber();
          nodes[target.id] = target;

          const rel = r.get("rel");
          return Object.assign({ source: source.id, target: target.id }, rel);
        });
        session.close();
        console.log("Neo4j query: " + neo4jQuery);
        console.log(
          links.length + " links loaded in " + (new Date() - start) + " ms."
        );

        const gData = { nodes: Object.values(nodes), links: links };
        const nodeColorScale = d3.scaleSequential(d3.interpolateBlues);
        const linkColorScale = d3.scaleSequential(d3.interpolateReds);
        const { width, height } = divRef.current.getBoundingClientRect();

        ForceGraph3D()(elem)
          .width(width * 0.95)
          .height(height * 0.7)
          .backgroundColor("#f5f5f5")
          .graphData(gData)
          .nodeColor((node) => nodeColorScale(node.weight))
          .nodeVal("size")
          .linkColor((link) => linkColorScale(link.weight))
          .linkWidth("size")
          .nodeLabel(
            (node) => `<span
                style="color: #0A4650; text-shadow: 2px 2px #BBEB02; font-size: 1.5rem;"
              >
                ${node.caption}
              </span>`
          )
          .onNodeHover((node) => {
            elem.style.cursor = node ? "pointer" : null;
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [topEntitiesPercentage, topRelationsPercentage]);

  return (
    <div
      ref={divRef}
      style={{
        width: "100%",
        height: "100%",
        padding: "1rem",
        backgroundColor: "#f5f5f5",
      }}
    >
      <p>
        <b> GenAI Topic Cluster </b>
      </p>
      <div>
        <label>Showing results for top: &nbsp; </label>
        <Select
          val={topEntitiesPercentage}
          setVal={setTopEntitiesPercentage}
          range={10}
          increment={10}
        />
        <label>&nbsp; with relevance: &nbsp; </label>
        <Select
          val={topRelationsPercentage}
          setVal={setTopRelationsPercentage}
          range={10}
          increment={10}
          ascending={false}
        />
      </div>
      <div id="3d-graph" />
    </div>
  );
}

export default ThreeDGraph;
