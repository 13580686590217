import React from "react";
import "./SearchBar.css";

const SearchBar = ({ startDate, endDate, setStartDate, setEndDate }) => {
  const handleStartDateChange = (e) => {
    setStartDate(new Date(e.target.value));
  };

  const handleEndDateChange = (e) => {
    setEndDate(new Date(e.target.value));
  };

  return (
    <div className="search-bar-container d-flex justify-content-between align-items-center">
      <div className="date-range-selector d-flex align-items-center">
        <span className="me-2" style={{ color: "#0A4650" }}>
          {" "}
          Select period:
        </span>
        <input
          type="date"
          className="form-control me-2"
          value={startDate.toISOString().split("T")[0]}
          onChange={handleStartDateChange}
        />
        <span className="me-2" style={{ color: "#0A4650" }}>
          to
        </span>
        <input
          type="date"
          className="form-control"
          value={endDate.toISOString().split("T")[0]}
          onChange={handleEndDateChange}
        />
      </div>
      <div
        className="search-and-filter d-flex align-items-center"
        style={{ paddingRight: "1rem" }}
      >
        <div className="input-group me-2">
          <input
            type="text"
            className="form-control search-input"
            placeholder="Search..."
            aria-label="Search"
          />
          <span className="input-group-text search-icon-container">
            <i className="bi bi-search search-icon"></i>
          </span>
        </div>
        <button className="btn btn-outline-secondary filter-button">
          <i className="bi bi-funnel"></i>
        </button>
        <span className="me-2" style={{ color: "#0A4650" }}>
          {" "}
          Filter{" "}
        </span>
      </div>
    </div>
  );
};

export default SearchBar;
