import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Loading from "./LoadingAnimation";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TimeseriesChart = ({
  data,
  isLoaded = true,
  error = null,
  title = "Timeseries Data",
  labelKey = "key",
  valueKey = "value",
}) => {
  // Sort data by date
  const sortedData = data.sort(
    (a, b) => new Date(a[labelKey]) - new Date(b[labelKey])
  );

  // Format dates to be more readable
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
  };

  const chartData = {
    labels: sortedData.map((item) => formatDate(item[labelKey])),
    datasets: [
      {
        data: sortedData.map((item) => parseInt(item[valueKey])),
        borderColor: "#2b86b3",
        backgroundColor: "rgba(43, 134, 179, 0.1)",
        borderWidth: 2,
        fill: true,
        tension: 0.3,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => `Count: ${context.formattedValue}`,
          title: (contexts) => {
            return formatDate(sortedData[contexts[0].dataIndex][labelKey]);
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 10,
          },
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "#f0f0f0",
        },
        ticks: {
          font: {
            size: 10,
          },
        },
      },
    },
  };

  return (
    <div
      style={{
        backgroundColor: "#F5F5F5",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        minHeight: "300px",
      }}
    >
      <b>{title}</b>
      {error ? (
        <p className="text-red-500" style={{ marginTop: "10px" }}>
          Sorry! An error occurred while fetching the data.
        </p>
      ) : isLoaded && data.length === 0 ? (
        <p className="text-gray-500" style={{ marginTop: "10px" }}>
          No data available
        </p>
      ) : isLoaded ? (
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <div style={{ width: "100%", height: "300px" }}>
            <Line data={chartData} options={options} />
          </div>
        </div>
      ) : (
        <p style={{ marginTop: "10px" }}>
          <Loading />
        </p>
      )}
    </div>
  );
};

export default TimeseriesChart;
